<template>
  <div class="testStudy">
    <div class="header">
      <i class="el-icon-alifanhui" @click="back"></i>
      <div>通过性自我测验</div>
      <div></div>
    </div>
    <div class="testMain">
      <div class="tipsBox">
        <div class="tipsLeft">
          <p @click="clickUp">上一题</p>
        </div>
        <div class="tipsRight">
          <span>已通过：5/24</span>
          <span>|</span>
          <span>未掌握：2</span>
        </div>
      </div>
      <template v-if="quesType == 1">
        <div class="quesMain" v-for="(item,index) in quesList" :key="index">
          <p class="quesTitle">{{item.title}}（ ）</p>
          <ul class="answerList">
            <li ref="liList" v-for="(item2,index2) in item.answerList" :key="index2" @click="clikcAnswer(item,item2,index2)">
              {{item2.option}}.{{item2.content}}
            </li>
          </ul>
          <div class="analysis" v-if="optionType">
            <p class="analysisTitle" @click="clickAnalysis"><i class="el-icon-aliyanjing"></i>答案解析</p>
          </div>
          <div class="analysisInfo" v-if="analysisType">
            <div class="infoTitle">
              <div></div>
              <p>答案解析</p>
              <i class="el-icon-alicuowuguanbiquxiao" @click="closeAnalysis"></i>
            </div>
            <div class="infoAnswer">【答案】：C</div>
            <div class="infoAnswer">【解析】：</div>
            <div class="infoContent">
              <span>穿刺部位</span>
              <p>旋叩诊为实音及呼吸音明显减低处，一般常选腋后线与肩胛下角线之间第7～9肋间，也可在腋中线第6～7肋间穿刺</p>
              <p>现多作B型超声检查确定穿刺点及进针深度，并应注意参照X线检查结果及查体情况。</p>
              <p>包裹性积液及少量积液者，则必须于X线检查及B型超声检查标记定位后穿刺或超声引导下穿刺。</p>
              <p>气胸穿刺术穿刺部位</p>
              <p>参照胸部透视或拍片结果，穿刺点取第2～3肋间锁骨中线处，或第4～5肋间腋前线处。</p>
              <p>参照胸部透视或拍片结果，穿刺点取第2～3肋间锁骨中线处，或第4～5肋间腋前线处。</p>
              <p>参照胸部透视或拍片结果，穿刺点取第2～3肋间锁骨中线处，或第4～5肋间腋前线处。</p>
              <p>参照胸部透视或拍片结果，穿刺点取第2～3肋间锁骨中线处，或第4～5肋间腋前线处。</p>
              <p>如为张力性气胸，病情危急无法作X线检查时，可按上述部位直接作诊断性穿刺。</p>
            </div>
          </div>
          <div class="nextQues" v-if="optionType" @click="clickNext">下一题</div>
        </div>
      </template>
      <template v-if="quesType == 2">
        <div class="selfTest">
          <div class="selfTestTitle">知识自测</div>
          <div class="selfTestTitleImg">
            <img src="../../assets/img/selfTestTitleImg.png" alt="">
          </div>
          <div class="selfTestInfo">
            <span>胸腔穿刺抽液引起急性肺水肿是由于：</span>
            <p>胸腔穿刺抽液时，抽液过快胸膜腔内压力下降太快，而肺内压力比较高，这样血液就会随着压力深入到间质和肺泡内，导致肺水肿。所以不能抽的太快也不能抽的太多，太多也会发生水肿！</p>
          </div>
          <div class="selfTestBtn">
            <div class="notMaster" @click="clcikMaster">没有掌握</div>
            <div class="secondaryMaster" @click="clcikMaster">模棱两可</div>
            <div class="wholeMaster" @click="clcikMaster">完全掌握</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      quesList: [{
        title: "有关胸腔穿刺的方法,下列哪项不正确：",
        correct: "C",
        answerList: [{
          option: "A",
          content: "穿刺抽液时,穿刺点取浊音明显部位,一般取肩胛线7—9肋间隙或腋中线6—7肋间。",
        }, {
          option: "B",
          content: "穿刺抽气时,穿刺点取患侧锁骨中线第2肋间。",
        }, {
          option: "C",
          content: "穿刺时应沿肋骨下缘进针。",
        }, {
          option: "D",
          content: "抽液量每次不超过1000ml。",
        }, {
          option: "E",
          content: "抽气量每次可大于1000ml。",
        }]
      }],
      currIndex: -1,
      optionType: false,
      analysisType: false,
      quesType: 2,
    }
  },
  methods: {
    // 点击左上角返回
    back() {
      this.$router.push({ name: "home" });
    },
    // 点击上一题
    clickUp() {
      this.optionType = false;
      if (this.quesType == 1) {
        this.quesType = 2;
      } else {
        this.quesType = 1;
      }
    },
    // 点击答案
    clikcAnswer(item, item2, index2) {
      if (item.correct == item2.option) {
        this.$refs.liList[index2].classList.add("correctCss");
        this.quesType = 2;
      } else {
        this.$refs.liList[index2].classList.add("errorCss");
        let corrIndex;
        item.answerList.forEach((v, index) => {
          if (v.option == item.correct) {
            corrIndex = index;
          }
        });
        this.$refs.liList[corrIndex].classList.add("correctCss");
        this.optionType = true;
      }
    },
    // 点击答案解析
    clickAnalysis() {
      this.analysisType = true;
    },
    // 点击答案解析关闭
    closeAnalysis() {
      this.analysisType = false;
    },
    // 点击下一题
    clickNext() {
      this.quesType = 2;
      this.optionType = false;
    },
    // 点击知识卡片下面按钮
    clcikMaster() {
      this.quesType = 1;
      this.optionType = false;
    }
  },
  mounted() { },
}
</script>

<style>
.testStudy .header {
  width: 100%;
  height: 44px;
  line-height: 44px;
  text-align: center;
  color: #101010;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  background: #f8f8f8;
  z-index: 999;
}
.testStudy .header i {
  margin: 0 18px;
  font-size: 20px;
  display: block;
  width: 30px;
}
.testStudy .header div:last-child {
  width: 30px;
  margin-right: 18px;
}
.testStudy .testMain {
  width: 100%;
  height: calc(100vh - 44px);
  padding: 0 30px;
  margin-top: 44px;
}
.testStudy .tipsBox {
  width: 100%;
  height: 50px;
  line-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.testStudy .tipsBox .tipsLeft {
  color: #008cfd;
  font-size: 13px;
}
.testStudy .tipsBox .tipsRight {
  font-size: 13px;
  color: #797979;
}
.testStudy .tipsBox .tipsRight span:nth-of-type(2) {
  margin: 0 10px;
}

/* 题目卡片CSS */
.testStudy .quesTitle {
  font-size: 16px;
}
.testStudy .answerList li {
  width: 100%;
  padding: 5px 12px;
  box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  line-height: 20px;
  margin-top: 12px;
}
.testStudy .answerList .errorCss {
  background: #ffcdbd;
}
.testStudy .answerList .correctCss {
  background: #97fdc8;
}
.testStudy .analysis {
  margin-top: 20px;
}
.testStudy .analysis .analysisTitle {
  color: #008cfd;
  font-weight: bold;
}
.testStudy .analysis .analysisTitle i {
  font-size: 16px;
  margin-right: 5px;
  font-weight: bold;
}
.testStudy .analysisInfo {
  width: 100%;
  height: 60vh;
  background: #fff;
  position: absolute;
  bottom: 60px;
  left: 0px;
  border-radius: 8px 8px 0 0;
  padding: 0px 30px 20px;
  overflow: auto;
  box-shadow: 2px 0px 1px 0px rgba(0, 0, 0, 0.4);
}
.testStudy .analysisInfo .infoTitle {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;
  position: sticky;
  top: 0;
  background: #fff;
  height: 50px;
  align-items: center;
}
.testStudy .analysisInfo .infoTitle div {
  width: 24px;
}
.testStudy .analysisInfo .infoTitle i {
  font-size: 24px;
}
.testStudy .analysisInfo .infoAnswer {
  margin-bottom: 10px;
}
.testStudy .analysisInfo .infoContent {
  margin-bottom: 10px;
}
.testStudy .analysisInfo .infoContent p {
  text-indent: 2em;
  line-height: 24px;
}
.testStudy .nextQues {
  position: absolute;
  bottom: 20px;
  left: 30px;
  width: calc(100% - 60px);
  height: 38px;
  line-height: 38px;
  text-align: center;
  background: #008cfd;
  color: #fff;
}

/* 知识卡片CSS */
.testStudy .selfTest .selfTestTitle {
  font-size: 16px;
  color: #ffc000;
}
.testStudy .selfTest .selfTestTitleImg {
  width: 100%;
  height: 169px;
}
.testStudy .selfTest .selfTestTitleImg img {
  width: 100%;
  height: 100%;
  margin-top: 12px;
}
.testStudy .selfTest .selfTestInfo {
  margin-top: 24px;
}
.testStudy .selfTest .selfTestInfo p {
  margin-top: 10px;
  line-height: 30px;
}
.testStudy .selfTest .selfTestBtn {
  position: absolute;
  bottom: 20px;
  left: 30px;
  width: calc(100% - 60px);
  display: flex;
  justify-content: space-between;
}
.testStudy .selfTest .selfTestBtn div {
  text-align: center;
  padding: 10px 15px;
  color: #fff;
  border-radius: 8px;
}
.testStudy .selfTest .selfTestBtn .notMaster {
  background: #ea8a6a;
}
.testStudy .selfTest .selfTestBtn .secondaryMaster {
  background: #eac26a;
}
.testStudy .selfTest .selfTestBtn .wholeMaster {
  background: #7fdb62;
}
</style>